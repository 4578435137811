@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* helper classes */
.flex-row{
  display:flex;
}

.flex-bottom{
  align-items:flex-end;
}

.flex-row h1, .flex-row p{
  margin:0;
}

.block-mobile{
  display:block;
}

.bottombox{
  background-color: #EEEEEE;
}

.bg--purple{
  background: linear-gradient(90deg, #9552D0 0%, #E09AF0 101.88%);
}

.bg--blue{
  background: #6166D6;
}

.bg--pink{
  background-color: #FFD600;
}

.bg--light-blue{
  background: #edf3fd;
}

/* layout classes */
.wrapper{
  height: 100%; /* Full-height: remove this if you want "auto" height */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  max-width: 400px;
  display:none;
}

.account-settings {
  margin: 20px 37.5px;
}

.header {
  padding:12px 24px;
  height:100%;
  color: white;
  background: conic-gradient(from 180deg at 50% 74.91%, #FFD600 -26.25deg, #311991 88.66deg, #AE479E 181.87deg, #FFD600 333.75deg, #311991 448.66deg);
}

h1 {
  font-family: 'Roboto';
  font-style: normal;
  background: conic-gradient(from 231.79deg at 50% 50%, #FFFFFF -54.38deg, #FEFCFF 3.71deg, #FFFBDA 3.75deg, #FDEEFF 159.28deg, #F8CAFF 215.35deg, #FFFFFF 305.62deg, #FEFCFF 363.71deg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin:0;
}

.header-title {
}

.closeIcon{
  float: right;
  padding: 10px 7px 0 0;
}

.header-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: rgba(238, 238, 238, 0.7);
}

.header-nav-item {
}

.left-nav-items-container {

}

.left-nav-item {

}

.left-nav-item a {
  font-family: 'Roboto';
  font-size:20px;
  font-weight:600;
  text-decoration: none;
  color: #C9BED8;
}

.left-nav-item.active a {
  color:#FFF;
}

.right-nav-items-container {
  
  
}

#responseScroll{
  box-sizing: border-box;
  position:relative;
  height:300px;
  overflow-y:scroll;
  left:-80px;
  width:calc(100% + 160px);
  padding:0 80px;
  scrollbar-color: #000 transparent;
  scrollbar-width: thin;
}

.avatar-right, .avatar-left{
  position:relative;
}

.avatar-right:after{
  content:'DM';
  right: -56px;
  background-color: #FF8AF3;
}

.avatar-left:after{
  content:'';
  left:-56px;
  background: url(assets/aiavatar.png) no-repeat center center;
  background-size:contain;
}

.avatar-right:after, .avatar-left:after{
    position: absolute;
    display:block;
    
    width:42px;
    height:42px;
    border-radius: 50%;
    line-height: 42px;
    color: #0F054A;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    top: calc(50% - 21px);
    text-align: center;
}

.right-nav-item {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.right-nav-item.active-button{
  background-color: #0F054A;
}

.right-nav-item.active-button > svg{
  fill:#FFF !important;
  transform: rotate(45deg);
}

.date-nav-item {
  margin-right: 20px;
}

.nav-filter {
  position: relative;
  background: #FFFFFF;
  height: 38px;
  color: black;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  border:0 none;
  font-size:20px;
  font-weight:600;
}

.nav-filter.active{
  background-color:#0F054A;
  color:#FFF;
}


.nav-filter.active:after{
  content:'';
  display:block;
  width:18px;
  height:18px;
  background:url(assets/close-x.svg) no-repeat center center;
  background-size:contain;
  position: absolute;
  right:5px;
  top:11px;
}

.nav-filter-text {
  display: flex;
}

.font-black {
  color: black;
}

.plus-icon {
  margin-top: 3px;
  height: 2em;
  width: 2.2em;
}

.search-bar {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 200px;
  height: 40px;
  margin-right: 20px;
  padding-right: 80px;
  padding-left: 10px;
  display: flex;
}

.search-icon {
  margin-top: 5px;
  padding-right: 10px;
  width: 25px;
  height: 25px;
}

.search-input {
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  text-decoration: underline;
  color: #EEEEEE;
}

.close-icon {
  margin-top: 8px;
  width: 22px;
  height: 22px;
}

/* sidbar */

.sidebar {
  background-color: #10064A;
  color: white;
  height: calc(100% - 92px);
  padding:46px 34px;
  display:none;
  position: relative;
}

.sidebar-header {
  margin-bottom:88px;
  position: relative;
}

.sidebar-header img{
  display:block;
  width:100%;
  height:auto;
  max-width: 115px;
  transition: opacity 0.3s ease-out;
}

.sidebar-header .logo-mini{
  height: 34px;
  width:auto;
  position:absolute;
  opacity:0;
  top:0;
  left:-12px;
}

.btn-sidebar{
  background:none;
  border: none;
  padding:0;
  position: relative;
  position: absolute;
  right: 0;
  bottom: 15vh;
  cursor: pointer;
}

.btn-sidebar img{
  transition: all 0.3s ease-out;
}

.sidebar-open{
  position: absolute;
  opacity: 0;
}

.sidebar{
  overflow:hidden;
  max-width:1000px;
  transition: all 0.3s ease-out;
}

.sidebar.closed{
  max-width:36px;
}

.sidebar.closed .sidebar-open{
  opacity: 1;
}

.sidebar.closed .sidebar-close{
  opacity: 0;
}

.sidebar.closed .sidebar-header .logo{
  opacity: 0;
}

.sidebar.closed .sidebar-header .logo-mini{
  opacity: 1;
}

.sidebar-list, .sidebar-link{
  transition: all 0.3s ease-out;
}

.sidebar.closed .sidebar-list, .sidebar.closed .sidebar-link{
  color:#10064A !important;
}

.sidebar.closed .footer{
  display: none;
}

.sidebar-icons {
  align-items: center;
  margin-right: 10px;
  width: 20px;
  justify-content: center;
}

.sidebar-link {
  text-decoration: none;
  color: #8F8F8F;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.sidebar-list {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 5px;
}

.footer {
  align-items: 'center';
  display: 'flex';
  justify-content: 'space-between';
  padding-left: 25;
  background-color: #10064A;
  color: white;
}

#footer-link{
  text-decoration: none;
  color: #ffffff;
  font-weight:600;
}

.footer-text {
  font-size:12px;
  text-decoration: none;
  color: #ffffff;
  opacity: 30%;
}

/* Card styles */

.main-content-container {
  
}

.card-wrapper{
  position:relative;
}

.card-image{
  width:100%;
  height:235px;
  border-radius: 4px;
}

.card-percentage-image {
  position: relative;
}

.card-percentage-text1{
  top: 7%;
  left: 8%;
  color:white; 
  font-size:30px; 
  font-weight: bold; 
  position: absolute;
}

.card-percentage-text2{
  top: 7%; 
  left:34%; 
  color:white; 
  font-size:30px; 
  font-weight: bold; 
  position: absolute;
}

.card-percentage-text3{
  top: 50%; 
  left: 34%; 
  color:white; 
  font-size:30px;
  font-weight: bold; 
  position: absolute;
}

.card-percentage-text4{
  top: 10%; 
  left: 10%; 
  font-size:38px; 
  font-weight: bold; 
  position: absolute;
}

.card-percentage-text-usage {
  top: 10%; 
  left: 10%;
  font-size:15px; 
  font-weight: bold; 
  position: absolute;
  opacity: 0.5;
}

.bg--blue, .bg--pink, .bg--purple{
  color:#FFF;
}

.right-nav-item{
  position: relative;
}

.overlay__quicklinks{
  position: absolute;
  flex-wrap: wrap;
  background: #0F054A;
  border-radius: 5px 5px 10px 10px;
  width:274px;
  right:0;
  z-index: 2000;
  top:42px;
  overflow: hidden;
  display: none;
}

.active .overlay__quicklinks{
  display: flex;
}

.plus-icon{
  cursor: pointer;
}

.active .plus-icon{
  fill: #0F054A;
  color:#FFF;
}

.quick_link{
  display:block;
  width: 50%;
  background: none;
  border: none;
  color:#FFF;
  text-align: center;
  height:72px;
  font-size:14px;
  font-weight: 600;
  cursor: pointer;
  border-bottom:1px rgba(255, 255, 255, 0.2) solid;
  border-left:1px rgba(255, 255, 255, 0.2) solid;
}

.quick_link:hover{
  background-color:#231A58;
}

.quick_link:before{
  content: "";
  background:url(assets/icon-purple-backforth.svg) no-repeat center center;
  display: block;
  width:16px;
  height:16px;
  margin:0 auto 8px;
}

.quick_link.person::before{
  background-image: url(assets/icon-purple-person.svg);
}
.quick_link.network::before{
  background-image: url(assets/icon-purple-network.svg);
}
.quick_link.plus::before{
  background-image: url(assets/icon-purple-plus.svg);
}
.quick_link.icon-bolt:before::before{
  background-image: url(assets/icon-purple-bolt.svg);
}
.quick_link.alarm::before{
  background-image: url(assets/icon-purple-alarm.svg);
}

.flyout__feed__event__info{
  padding-left: 40px;
}

.icon-person:before, .icon-exclaim:before, .icon-bolt:before, .icon-network:before, .icon-provider:before{
  content: "";
  display: block;
  background: url(assets/icon-person.svg) no-repeat center center;
  background-size: contain;
  width:24px;
  height: 24px;
  position:absolute;
  left:12px;
  top:26px;
}

.icon-exclaim:before{
  background-image: url(assets/icon-exclaim.svg);
}

.icon-bolt:before{
  background-image: url(assets/icon-bolt.svg);
}

.icon-network:before{
  background-image: url(assets/icon-network.svg);
}

.icon-provider:before{
  background-image: url(assets/arrow-transfer-2.svg);
}

.eventsButton{
  padding:0;
  background: none;
  border:0 none;
  cursor: pointer;
}

.flyout__feed{
  border-top:1px rgba(255, 255, 255, 0.2) solid;
}

.flyout__feed__event{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  border-bottom:1px rgba(255, 255, 255, 0.2) solid;
  padding:28px 10px 18px;
}

.flyout__feed__event P{
  color:#7E7E7E;
  margin:0;
}

.flyout__feed__event P strong{
  color:#FFF;
}

.flyout__feed__event:hover:before{
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  top:0;
  left:-28px;
  width:calc(100% + 56px);
  background-color: rgba(217, 217, 217, 0.1);
}

.flyout__feed__event__change{
  min-width:75px;
  text-align: right;
}

.flyout__feed__event h4{
  font-size:17px;
  font-weight:500;
}

.flyout__feed__event__change.decrease{
  color:#FF9A9A;
}

.flyout__feed__event__change.decrease:before{
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: url(assets/icon-arrow-up.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 28px;

}

.flyout__feed__event__change.middle:before{
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: url(assets/icon-arrow-middle.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 28px;
}

.flyout__feed__event__change.increase:before{
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: url(assets/icon-arrow-wayup.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 28px;

}

.flyout__feed__event__change.middle{
  color:#FFD79A;
}

.flyout__feed__event__change.increase{
  color:#BAFF9A;
}

.flyout__feed__event__time{
  max-width:150px;
}

.flyout__feed{
  max-height:70vh;
  overflow-y: scroll;
}

.flyout__header H3, .flyout__feed__event H4, .flyout__header H6{
  margin:0;
}

.flyout__header h3{
  font-size:32px;
  margin-bottom:10px;
  font-weight:500;
}
.flyout__header h6{
  font-size:16px;
  margin-bottom:24px;
  font-weight:500;
}

.flyout__feed__event__info{
  width:60%;
}

.filterDropDown{
  display:none;
  position:absolute;
  z-index: 20;
  width:300px;
  background:#0F054A;
  border-radius:4px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding:18px;
  transform: translate(-190px, 8px);
}

.filterDropDown .MuiAccordionSummary-root{
  min-height: unset !important;
}

.active + .filterDropDown{
  display:block;
  
}

.filterDropDown__header{
  display:flex;
  align-items:flex-start;
}

.filterDropDown__header h3{
  color:#FFF;
  margin:0;
  margin-right:auto;
  margin-bottom:18px;
}

.filterDropDown__header button{
  font-family: 'Roboto';
  background:none;
  border:none;
  padding:0;
  color:#FFF;
  font-size:14px;
  line-height: 22px;
  cursor: pointer;
  display: inline-block;
  font-weight:300;
}

.filterDropDown__header span{
  color:#FFF;
}

.filterSubmit{
  font-family: 'Roboto';
  border:none;
  padding:0;
  background:none;
  color:#FFF;
  font-size:20px;
  font-weight:800;
  cursor: pointer;
}

.filterDropDown .MuiSwitch-thumb{
  width:12px;
  height:12px;
  transform:translate(4px, 5px);
}

.filterDropDown__footer{
  padding:32px 0 4px;
  display:flex;
  justify-content:flex-end;
}

#panel3a-content .MuiFormControlLabel-root{
  border:1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 0 5px 5px;
  display:flex;
  justify-content: space-between;
  padding-left:12px;
}

#panel2a-content .MuiTypography-root{
  font-weight:600;
}

#panel2a-content .MuiFormControlLabel-root{
  margin-bottom:6px;
}

#panel3a-content .MuiFormControlLabel-root:first-child{
  border-bottom:0 none;
  border-radius: 5px 5px 0 0;
}

#panel3a-content .MuiSwitch-root{
  order:2;
}



.MuiAccordionSummary-content:before{
  content:'';
  position: relative;
  top:6px;
  display:inline-block;
  width: 0;
  height: 0;
  margin-right:5px;
  border-style: solid;
  border-width: 10px 6px 0 6px;
  border-color: #FFFFFF transparent transparent transparent;
  transform: rotate(-90deg);
  transition: all 0.4s ease-out;
}
.MuiAccordionSummary-content.Mui-expanded:before{
  transform: rotate(0);
}

.dateDropDown{
  position:absolute !important;
  top:20px;
  right:20px;
}

.dateButton{
  display:inline-block;
  background: #F9F6F6;
  border: 1px solid #68216F !important;
  border-radius: 5px !important;
  text-transform: none !important;

  &:after{
    content:'';
    display:inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 3px 0 3px;
    border-color: #a4a4a4 transparent transparent transparent;
    margin-left:4px;
  }
}

.displayFlex{
  display:flex;
}

.positionAbsolute{
  position:absolute;
  z-index: 1;
}

.react-flow__controls-button svg{
  display:none;
}

.react-flow__controls-button{
  width:30px !important;
  height:30px !important;
  border:none !important;
  background-size:contain;
  cursor: pointer;
}

.react-flow__controls-zoomin{
  background:transparent url(assets/add-step.svg) no-repeat center center !important;
}
.react-flow__controls-zoomout{
  background:transparent url(assets/zoom-out.svg) no-repeat center center !important;
}
.react-flow__controls-fitview{
  background:transparent url(assets/full-screen.svg) no-repeat center center !important;
}
.react-flow__controls-interactive{
  background:transparent url(assets/lock.svg) no-repeat center center !important;
}

.organizeNodes{
  border: none;
  background:url(assets/organize.svg) no-repeat left center;
  background-size:auto 30px;
  width:30px;
  height:30px;
  cursor: pointer;
  border-radius:15px;
  transition: all 0.2s ease-out;
}

.organizeNodes:hover{
  width:103px;
}


.react-flow__node-default:not(.baseNodeSpinner, .suggestedSteps){
  border-radius: 5px;
  border: 1px solid #B0B0B0;
  background: linear-gradient(92deg, rgba(219, 197, 237, 0.95) 1.84%, rgba(237, 228, 197, 0.95) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #000;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding:22px 40px !important;
  width:200px !important;
}

.baseNodeSpinner{
  border: none;
  background: url(assets/trigger-closed.svg) no-repeat center center !important;
  background-size: contain !important;
  width: 30px !important;
  height: 30px;
}

.baseNodeSpinner > div{
  display:none;
}

.suggestedSteps{
  color: rgba(255, 255, 255, 0.70) !important;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(176, 176, 176, 0.20) !important;
  background: linear-gradient(267deg, rgba(255, 230, 0, 0.70) -6.73%, rgba(255, 65, 225, 0.70) 51.09%, rgba(133, 38, 253, 0.70) 95.04%) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  padding:8px 12px !important;
}

.triggerContainer{
  display:flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  z-index: 2;
}

.setupTrigger{
  border-radius: 5px;
  border: 0.5px solid rgba(176, 176, 176, 0.20);
  background: linear-gradient(93deg, rgba(103, 58, 177, 0.80) 2.63%, rgba(159, 37, 125, 0.80) 100%, rgba(159, 37, 125, 0.80) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #FFF;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width:200px;
  padding:5px 10px;
  margin-bottom:3px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.setupTrigger.sub{
  padding:3px;
}

.setupTrigger .text{
  text-align: left;
  flex-grow: 1;
}

.setupTrigger .text span{
  display:block;
  color: #C3C3C3;
  font-size: 7.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.setupTrigger .logo{
  background:#F8EFFF url(assets/workday-logo.png) no-repeat center center !important;
  background-size: auto 17px;
  border-radius: 5px;
  width:24px;
  height:24px;
  margin-right:8px;
}

.setupTrigger .edit{
  background:none;
  border:none;
  padding:0;
  line-height: 1;
  background:transparent url(assets/elipses.svg) no-repeat center center !important;
  background-size:contain;
  width:10px;
  height:2px;
}

.triggerButton{
  border-radius: 5px;
  border: 0.5px solid rgba(176, 176, 176, 0.20);
  background: linear-gradient(93deg, rgba(103, 58, 177, 0.80) 2.63%, rgba(159, 37, 125, 0.80) 100%, rgba(159, 37, 125, 0.80) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.addStep{
  border: none;
  background:url(assets/add-step-hover.svg) no-repeat left center;
  background-size:auto 30px;
  width:30px;
  height:30px;
  cursor: pointer;
  margin-right:4px;
  overflow: hidden;
  text-indent: -1000px;
  border-radius:15px;
  transition: all 0.2s ease-out;
}
.addStep:hover{
  width:103px;
}

.createTrigger{
  border: none;
  background:url(assets/trigger-button-hover.svg) no-repeat left center;
  background-size:auto 30px;
  width:30px;
  height:30px;
  cursor: pointer;
  margin-right:4px;
  overflow: hidden;
  text-indent: -1000px;
  border-radius:15px;
  transition: all 0.2s ease-out;
}
.createTrigger:hover{
  width:103px;
}

.slide-in-form{
  position: absolute;
  top: 127px;
  right: -360px;
  width: 360px;
  height: calc(100vh - 161px);
  border-radius: 15px 0px 0px 0px;
  border: 0.5px solid rgba(166, 166, 166, 0.20);
  background: #241C48;
  z-index: 1000;
  transition: all 0.3s ease-out;
}

.slide-in-form.slide-in{
  right:0;
}

.slide-form {
  margin:18px 36px;
}

.slide-form h2{
  color:#FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  border-bottom:1px solid #8A7EC3;
  padding-bottom:10px;
}

.slide-form input[type=text], .slide-form textarea{
  border-radius: 5px;
  background: #2E2456;
  border:none;
  margin-bottom:10px;
  color:#fff;
  font-size:14px;
  padding: 0.25em 1em 0.25em 1em;
  width:calc(180px - 2em);
}

.slide-form input[type=text]::placeholder, .slide-form textarea::placeholder{
  color: rgba(190, 182, 220, 0.50);
}

.slide-form textarea{
  width:calc(288px - 2em);
  margin-top:10px;
}

.slide-in-form > button{
  background: none;
  border: none;
  color: #3B306A;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.slide-form .select-style {
  background-color: linear-gradient(265deg, #2E2456 45.65%, #342456 79.32%);
  border: none;
  border-radius: 5px;
  display: inline-block;
  font: inherit;
  width:180px;
  line-height: 1.5em;
  padding: 0.25em 3.5em 0.25em 1em;
  margin: 0;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('assets/downarrow.svg'), linear-gradient(265deg, #2E2456 45.65%, #342456 79.32%);;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  font-size:14px;
  color: rgba(190, 182, 220, 0.50);
}

.slide-form button{

}

.slide-form label{
  color:#fff;
  font-size: 15px;
}

.slide-form .input-group{
  display:flex;
  justify-content: space-between;
}

.notes-group{
  margin-top:150px;
}

.leftWorkflow, .rightWorkflow{
  position: relative;
  top:-32px;
  height: calc(100vh - 200px);
}

.leftWorkflow h3{
  font-size:16px;
  color:#FFF;
  font-family: Roboto;
  font-weight:600;
  margin:0 0 20px;
  display:flex;
  justify-content: space-between;
}

.leftWorkflow h3 button{
  background:none;
  border:none;
  color:#6D6D6D;
  font-family: Roboto;
  font-size:32px;
  font-weight:300;
  cursor: pointer;
  line-height: 20px;
}

.leftWorkflow li{
  padding:0;
}

.leftWorkflow {
  margin-left:20px;
}

.leftWorkflow a, .rightWorkflow a{
  font-family: Roboto;
  color:#898989;
  font-weight:400;
  font-size:16px;
  line-height: 1.25;
  margin-bottom:20px;
  text-decoration: none;
}

.leftWorkflow .active a, .leftWorkflow a:hover{
  color:#FFF;
}

.rightWorkflow{
  margin-right:20px;
}

.react-flow__attribution{
  display:none !important;
}

.react-flow__controls{
  display:flex;
}

.react-flow__panel.top{
  top:-15px !important;
}

.bgPurple {
  background:#140D39;
}

.roundedCorners{
  border-radius:10px;
  padding:20px;
}

#aiVideo{
  width: 64px;
  height: auto;
}

@media screen and (min-width: 600px) {
  #aiVideo{
    position: absolute;
    transform: translate(-50%, -50%);
    width: 172px;
    left: 50%;
    top: 50%;
  } 
}

.timelineIcon{
  border:0 none;
  background:none;
  padding:8px 8px;
  cursor: pointer;
}

.dateRadios{
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding:18px;

  background: #FEFEFE !important;
  border: 0.05px solid rgba(0, 0, 0, 0.7) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
}


@media screen and (min-width: 600px) {
  .wrapper{
    display:block;
  }

  .gridwrap {
    display: flex;
    height:100vh;
  }

  .fullbox{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .bottombox{
    flex-grow: 1;
  }
  
  .header {
    padding:48px;
  }

  .header-nav-item{
    display:flex;
  }

  .header-text {
    position:relative;
    top:-3px;
    left:8px;
  }

  .sidebar{
    display:flex;
    flex-direction:column;
  }

  .sidebar ul{
    position:relative;
    left:-16px;
  }

  .sidebar ul > div:not(:last-child):hover{
    border-radius:5px;
    background: rgba(217, 217, 217, 0.15);
  }

  .sidebar .footer{
    margin-top:auto;
  }

  .left-nav-items-container{
    display:flex;
    align-items:flex-end;
  }

  .left-nav-item{
    padding:0 18px;
  }

  .left-nav-item:first-child{
    padding-left:0;
  }

  .right-nav-items-container{
    display: flex;
    margin-left:auto;
    position: relative;
    top:8px;
  }

  .right-nav-item {
    margin-right: 10px;
    height: 38px;
  }

  .date-nav-item p{
    font-size:18px;
    font-weight: 600;
    margin:8px 0 0;
    padding:0;
    line-height: 1;
  }

  .active .magnify-stroke{
    stroke:#FFF;
  }

  .main-content-container {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
    position:relative;
    top:-24px;
    left:16px;
    width:calc(100% - 32px);
    height:calc(100% + 3vh);
  }
}